.contactNum {
    border: 2px solid white;
    padding: 5px 40px;
    cursor: pointer;
    white-space: nowrap;
    width: min-content;
    transition: background-color 0.5s ease;
}
.contactNum p {
    font-size: 16px;
    transition: color 0.3s ease;
}
.contactNum:hover p{
    color: white !important;   
}
.buttonSize {
    margin: 20px auto;
    border: 3px solid #17E0AF !important;
    transition: background-color 0.5s ease;
    transition: border 0.5s ease;
}
.buttonSize:hover {
    background-color: #17E0AF;
    border: 3px solid #119e7b !important;
  }
  .contactNum:hover {
    background-color: #17E0AF;
    border: 2px solid #119e7b;
  }
  @media (min-width: 576px) {
    .contactNum {
      border: 2px solid white;
      
      padding: 10px 30px;
    }
    .buttonSize {
      width: 50%;
    }
  }
  
  @media (min-width: 768px) {
    .buttonSize {
      width: 385px;
    }
    
  }