footer img {
  height: 50px;
}
footer {
    position: relative; /* Ajout de position relative pour positionner correctement le pseudo-élément */
  background-image: url("../../assets/images/bg-footer.png");
  color: white;
}

.fontSizeLink {
  font-size: 11px;
}
#fontSizeCopyRight {
  font-size: 10px;
}
