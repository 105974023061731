header p {
    text-decoration: none;
    color: white;
    font-size: 12px;
}
header {
    background-color: rgba(55, 32, 14, 0.77);
    height: 60px;
    box-shadow: 0px 22px 10px #00000029;
    z-index: 500;
}

#containerLogoSwitch a,
#containerLogoCe a {
    display: block;
    height: 100%;
}


@media (min-width: 576px) {
    header {
        height: 95px;
    }
    .contactNum p,
    header p {
        font-size: 20px;
    
    }
    
}

@media (min-width: 992px) {
    #containerLogoSwitch {
        position: relative;
    }
    
    #containerLogoSwitch,
    #containerLogoSM {
        height: 60px;
        display: initial !important;
    }
    
    img {
        height: 60px;
    }
    
    
}