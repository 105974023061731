h1 {
    width: 80%;
    margin-top: 500px !important;
    color: white;
    background-color: rgba(255, 255, 255, 0.25);
    text-shadow: 3px 3px 3px black;
}

#iconeContrat {
    height: 70px;
}

#containerBanner {
    z-index: 100;
    background-image: url("../../assets/images/backgroundSouscrire.png");
    background-size: auto 100%;
    background-position-x:10%;
    z-index: 1;
}

.containerContact {
    border: 3px solid #11CFA1;
    height: 115px;
    width: 280px;
    background-color: white;
    /* padding: 5px 50px; */
    box-shadow: 0px 5px 9px #00000029;
    z-index: 100;
    transition: transform 0.5s ease;
    transition: border 0.5s ease;
}
.containerContact:hover {
    border: 3px solid #119e7b;
}
#containerButtons {
    height: 400px;
}
#containerButtons img {
    height: 60px;
}
#logoSwitch {
    height: 50px !important;
    width: 115px;
}

@media (min-width: 576px) {
    #containerButtons {
        height: 550px;
    }

    .containerContact {
        width: 350px;
        cursor: pointer;

    }

    #containerButtons img {
        height: 80px;
    }

}

@media (min-width: 768px) {


    #containerBanner h2 {
        font-size: 45px;
        max-width: 650px !important;
    }

    #iconeContrat {
        height: 125px;

    }
    #containerBanner h1 {
        width: 600px !important;
    }
    #containerButtons {
        width: 85%;
        z-index: 15;
        position: initial;
        /* top: 230px; */
    }
}

@media (min-width: 992px) {



    #containerBanner h2 {
        width: 50% !important;
        font-size: 60px !important;
    }

    #iconeContrat {
        height: 200px;
    }

   
    .positionContentBanner {
        padding-bottom: 310px !important;
    }
    #containerButtons {
        z-index: 15;
        height: 350px;
    
        width: 90%;
    }
    .moveButtons {
        top: 200px !important;
    }
}

@media (min-width: 1200px) {
  
    #containerButtons {
        width: 1100px;
    }
}
@media (min-width: 1450px) {
    #containerBanner {
        background-size:100%;
    }
}