
#formBanner {
    background: #11CFA1;
    height: 200px;
    margin-top: 95px;
}

#formBanner h1{
    font-size: 1.9rem;
    font-weight: 700 !important;
    text-transform: none !important;
    background-color: rgba(255, 255, 255, 0);
}
@media (min-width: 992px) {
    
    #formBanner h1{
        font-size: 4rem;
    }
}